import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };



  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

useEffect(() => {
  setFetchAttempted(true); // Set to true as fetch begins

  fetch('/api/location')
    .then(response => response.json())
    .then(data => {
      const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

      if (decodedCity === "unknown") {
        // If city is "unknown", fetch from the external API
        fetch('https://freeipapi.com/api/json/')
          .then(response => response.json())
          .then(externalData => {
            const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
            setCountry(externalData.countryCode);
            setCity(externalDecodedCity);
            console.log(externalData); // Log the external API data
          })
          .catch(error => {
            console.error('Fetch error on external API:', error);
          });
      } else {
        // Use the local API data
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
        setCity(decodedCity);
        console.log(data); // Log the local API data
      }
    })
    .catch(error => {
      console.error('Fetch error on local API:', error);
      // Optionally handle the error by fetching from external API or other means
    });

}, []);

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <div className="landing-container">
    {/* Video Background */}
    <video
      autoPlay
      playsInline
      preload="auto"
      loop
      muted
      className="video-background"
    >
      <source src="https://ofmpics.com/damnngg.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    {/* Main content */}
    <div className="container">
      <a className="header-logo" href="https://onlyfans.com/greedygrovegg">
        <div className="onlyfans"></div>
      </a>
      <img 
        src="https://i.ibb.co/BywrZvV/2024-03-30-23-53-52-modified.png" 
        alt="Header Image"
        className="header-image"
      />

      <div className='textContainer'>
        <section>
          <div className='username'><strong>Damn GG</strong></div>
          <div className='useraccountname'>@greedygrovegg</div>

          <div id="user-status" className="user-status">
            <div className="status"></div>
            <div id="available">Available now</div>
            <div className="separator"></div>
            <div className="clock"></div>  
            <div className="respond-time">Responds in 2 minutes</div>
          </div>
        </section>
      </div>

      <div className="textContainer">
        <div className="location"></div>
        <span id="location-name">{city ? `${city} ${region}` : '<3'}</span>
      </div>

      <div className="textContainer3">
        <a href="https://ofmsites.pro/greedygrovegg" id="customButton">
          <div className="onlyfans"></div>
          My NSFW Onlyfans page 🔞
        </a>
      </div>


      <div className="textContainer3">
                  <a href="https://onlyfans.com/itsfree_gg/c1" id="customButton">
                    <div className="onlyfans"></div>
                    My Free onlyfans page 
                  </a>
                </div>


                <div className="textContainer3">
                  <a href="https://cash.app/$AngelinaaInc" id="customButton2">
                    <div className="textContent">
                      Private Snapchat <br/>
                      <span className="small-text">100 to join for life, 1 time payment<br/>
                      Pay through cash app and add username in the message</span>
                    </div>
                  </a>
                </div>



      <div className="textContainer3">
        <a href="https://www.tiktok.com/@ZMJtG6r6a" id="customButton2">
          <div className="tiktok"></div>
          Tiktok
        </a>
      </div>

      <Analytics />
    </div>
  </div>

  );
}

export default App;
